export function closeModal() {
  return {
    type: 'MODAL_CLOSED',
  };
}

export function showLoginModal() {
  return {
    type: 'MODAL_LOGIN_SHOWN',
  };
}

export function showRegisterModal() {
  return {
    type: 'MODAL_REGISTER_SHOWN',
  };
}

export function showNewsletterModal() {
  return {
    type: 'MODAL_NEWSLETTER_SHOWN',
  };
}

export function showForgotPasswordModal() {
  return {
    type: 'MODAL_FORGOT_PASSWORD_SHOWN',
  };
}

export function showAddLibraryCardModal() {
  return {
    type: 'MODAL_ADD_LIBRARY_CARD_SHOWN',
  };
}
