import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { i18n } from 'utils/i18nUtils';
import { resetNetworkStatus } from 'middlewares/networkActions';
import { userRegister, REGISTER_ATTEMPTED } from '../../authActions';
import { showLoginModal } from 'customer/App/components/Modal/modalActions';
import Button from 'common/components/Button/Button';
import Input from 'common/components/Input/Input';
import PersonIcon from 'common/assets/icons/PersonIcon';
import PersonalNumberIcon from 'common/assets/icons/PersonalNumberIcon';
import EmailIcon from 'common/assets/icons/EmailIcon';
import LockIcon from 'common/assets/icons/LockIcon';
import './Register.scss';
import { PRIVACY_POLICY_PDFS_BROCHURE } from 'common/constants';
import { getCurrentCountry } from 'utils/i18nUtils';

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: null,
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      isCheckboxChecked: false,
      isNewsletterCheckboxChecked: false,
    };

    this.submit = this.submit.bind(this);
  }

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      isCheckboxChecked: !prevState.isCheckboxChecked,
    }));
  };

  handleNewsletterCheckboxChange = () => {
    this.setState((prevState) => ({
      isNewsletterCheckboxChecked: !prevState.isNewsletterCheckboxChecked,
    }));
  };

  getPrivacyPolicyPdfs() {
    switch (getCurrentCountry()) {
      case 'no':
        return PRIVACY_POLICY_PDFS_BROCHURE.no;
      case 'fi':
        return PRIVACY_POLICY_PDFS_BROCHURE.fi;
      default:
        return PRIVACY_POLICY_PDFS_BROCHURE.se;
    }
  }

  componentDidMount() {
    this.props.resetNetworkStatus(REGISTER_ATTEMPTED);
  }

  errorMessages = {
    INVALID_USERNAME: () => i18n('INVALID_REGISTRATION_USERNAME'),
    FIRSTNAME_TOO_LONG: () => i18n('FIRSTNAME_TOO_LONG'),
    LASTNAME_TOO_LONG: () => i18n('LASTNAME_TOO_LONG'),
    EXISTING_USER: () => i18n('USERNAME_ALREADY_EXISTS'),
    INVALID_EMAIL: () => i18n('INVALID_REGISTRATION_EMAIL'),
    EXISTING_EMAIL: () => i18n('EMAIL_ALREADY_EXISTS'),
    INVALID_PASSWORD: () => i18n('INVALID_REGISTRATION_PASSWORD'),
  };

  handleOnChangeInput(field, e) {
    this.setState({ [field]: e.target.value });
  }

  getError(code) {
    if (
      this.props.registerRequestStatus.error &&
      this.props.registerRequestStatus.errorCause.response.body.error_code === code
    ) {
      return this.errorMessages[this.props.registerRequestStatus.errorCause.response.body.error_code]();
    }
    return null;
  }

  submit(e) {
    e.preventDefault();
    this.props.userRegister(
      this.state.username,
      this.state.email,
      this.state.password,
      true, // login after register
      this.state.firstName,
      this.state.lastName,
      this.state.isNewsletterCheckboxChecked // Pass the newsletter checkbox state
    );
  }

  renderForm() {
    const privacyPolicyData = this.getPrivacyPolicyPdfs();
    const privacyPolicyLink = privacyPolicyData.pdfs;

    return (
      <form onSubmit={this.submit}>
        <div className="register-content">
          {this.props.titleText && <h2 className="register-title">{this.props.titleText}</h2>}
          {this.props.infoText && <p className="register-info">{this.props.infoText}</p>}
          {this.props.showLoginLink && (
            <p className="account-question">
              <span>{i18n('ALREADY_HAVE_ACCOUNT_QUESTION')} </span>
              <Button variant="link" size="md" modifier="action" onClick={this.props.showLoginModal}>
                {i18n('LOG_IN_HERE')}
              </Button>
            </p>
          )}
          <div className="input-fields">
            <Input
              className="input-username"
              icon={PersonalNumberIcon}
              type="text"
              placeholder={i18n('USERNAME')}
              theme={this.props.theme}
              value={this.state.username}
              onChange={(e) => this.handleOnChangeInput('username', e)}
              helpMessage={i18n('INVALID_REGISTRATION_USERNAME')}
              errorMessage={this.getError('INVALID_USERNAME') || this.getError('EXISTING_USER')}
            />

            <Input
              className="input-firstname"
              icon={PersonIcon}
              type="text"
              placeholder={i18n('YOUR_FIRSTNAME')}
              theme={this.props.theme}
              value={this.state.firstName}
              onChange={(e) => this.handleOnChangeInput('firstName', e)}
              errorMessage={this.getError('FIRSTNAME_TOO_LONG')}
            />

            <Input
              className="input-lastname"
              icon={PersonIcon}
              type="text"
              placeholder={i18n('LASTNAME')}
              theme={this.props.theme}
              value={this.state.lastName}
              onChange={(e) => this.handleOnChangeInput('lastName', e)}
              errorMessage={this.getError('LASTNAME_TOO_LONG')}
            />
            <Input
              className="input-email"
              icon={EmailIcon}
              type="email"
              placeholder={i18n('EMAIL')}
              theme={this.props.theme}
              value={this.state.email}
              onChange={(e) => this.handleOnChangeInput('email', e)}
              errorMessage={this.getError('INVALID_EMAIL') || this.getError('EXISTING_EMAIL')}
            />
            <Input
              className="input-password"
              icon={LockIcon}
              type="password"
              placeholder={i18n('PASSWORD')}
              theme={this.props.theme}
              value={this.state.password}
              onChange={(e) => this.handleOnChangeInput('password', e)}
              helpMessage={i18n('INVALID_REGISTRATION_PASSWORD')}
              errorMessage={this.getError('INVALID_PASSWORD')}
            />
          </div>
          <div>
            <input
              type="checkbox"
              className="checkbox"
              id="privacyCheckbox"
              checked={this.state.isCheckboxChecked}
              onChange={this.handleCheckboxChange}
            />
            <a href={privacyPolicyLink} className="privacypolicy" target="_blank" rel="noopener noreferrer ">
              {' '}
              {i18n('PRIVACY_POLICY')}
            </a>
          </div>
          <div>
            <input
              type="checkbox"
              className="checkbox"
              id="newsletterCheckbox"
              checked={this.state.isNewsletterCheckboxChecked}
              onChange={this.handleNewsletterCheckboxChange}
            />
            <label className="newsletter" htmlFor="newsletterCheckbox">
            {i18n('SUBSCRIBE_NEWSLETTER')}
            </label>
          </div>
        </div>
        <Button
          className="submit-button"
          type="submit"
          disabled={!this.state.isCheckboxChecked || this.props.registerRequestStatus.pending}
        >
          {this.props.submitButtonText}
        </Button>
        {this.props.onSkip && (
          <Button className="skip-button" type="button" variant="link" onClick={this.props.onSkip}>
            {this.props.skipButtonText}
          </Button>
        )}
      </form>
    );
  }

  renderSuccessMessage() {
    return (
      <div>
        <div className="register-success">
          <p>{this.props.successText}</p>
        </div>
        <Button className="submit-button" type="button" onClick={this.props.onDone}>
          {i18n('OK')}
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div className="register">
        {this.props.registerRequestStatus.success ? this.renderSuccessMessage() : this.renderForm()}
      </div>
    );
  }
}

Register.propTypes = {
  registerRequestStatus: PropTypes.shape(),
  showLoginLink: PropTypes.bool,
  titleText: PropTypes.string,
  infoText: PropTypes.string,
  submitButtonText: PropTypes.string.isRequired,
  skipButtonText: PropTypes.string,
  successText: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
  onDone: PropTypes.func,
  onSkip: PropTypes.func,
  newsletterSubscriptionStatus: PropTypes.string,
};

Register.defaultProps = {
  registerRequestStatus: {},
  showLoginLink: false,
  titleText: null,
  infoText: null,
  skipButtonText: null,
  theme: 'dark',
  onDone: () => {},
  onSkip: () => {},
  newsletterSubscriptionStatus: null,
};

function mapStateToProps(state) {
  return {
    registerRequestStatus: state.get('networkReducers')[REGISTER_ATTEMPTED],
    newsletterSubscriptionStatus: state.getIn(['authReducers', 'newsletterSubscriptionStatus']),
  };
}

export default connect(mapStateToProps, {
  showLoginModal,
  userRegister,
  resetNetworkStatus,
})(Register);
