/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { browserHistory } from 'react-router';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import CompatibilityWarning from './components/CompatibilityWarning/CompatibilityWarning';
import LoadingBar from 'common/components/LoadingBar/LoadingBar';
import Banner from './components/Banner/Banner';
import Modal from 'customer/App/components/Modal/Modal';
import { getLastSelectedLibrary } from 'utils/localStorageUtils';
import { loadLibrary, loadConfiguration, loadCountryConfiguration } from './appActions';
import { loadLibraries } from '../SelectLibrary/selectLibraryActions';
import { fetchUserData, setUserData, userLogout } from 'customer/Auth/authActions';
import { initialLoginData } from 'app';
import './app.scss';
import { i18nUrl, goToURL, getAvailableLanguages } from '../../utils/i18nUtils';
import ErrorPopup, { triggerErrorPopup } from 'common/err/ErrorPopup';
import CookieConsent from '../../cookieconsent';
import libraryTheme from '../../utils/themeUtils';
import { showNewsletterModal } from './components/Modal/modalActions';
import { checkNewsletterShown } from './appActions';

export const redirectIfNotLoggedIn = (userData) => {
  if (!initialLoginData && !userData) {
    // User has no active login-session in the backend and needs to log in
    browserHistory.push(i18nUrl('/'));
    return true;
  }
  return false;
};

const getLibraryId = () => {
  const urlArray = window.location.pathname.split('/');
  const libraryIndex = urlArray.findIndex((el) => el === 'library');
  return libraryIndex ? urlArray[libraryIndex + 1] : libraryIndex;
};

class App extends Component {
  constructor(props) {
    super(props);

    this.defaultLibraryId = getLastSelectedLibrary();
    this.isFetchingLibrary = false;
    this.lastFetchedLibraryId = -1;
    this.state = {
      errorPopupOnError: true,
    };
  }

  componentWillMount() {
    this.props.setUserData(initialLoginData);
    this.props.loadConfiguration();
    this.props.loadCountryConfiguration();
    this.props.loadLibraries();

    if (this.props.userData) {
      const userEmail = this.props.userData.get('email');
      this.props.checkNewsletterShown(userEmail);
    }
    if (this.defaultLibraryId !== -1) {
      this.fetchLibrary(this.defaultLibraryId);
    }
  }

  componentWillUpdate(newProps) {
    const oldId = this.props.library && this.props.library.get('id');
    const newId = parseInt(newProps.params.libraryId);
    if (oldId !== newId && newId !== this.lastFetchedLibraryId && newId) {
      this.fetchLibrary(newId);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.userData && this.props.userData) {
      const userEmail = this.props.userData.get('email');
      this.props.checkNewsletterShown(userEmail);
    }
  }

  fetchLibrary(id) {
    if (!this.isFetchingLibrary) {
      this.isFetchingLibrary = true;
      this.lastFetchedLibraryId = id;
      this.props.loadLibrary(id).then(() => (this.isFetchingLibrary = false));
    }
  }

  isEhubRedirect() {
    return !!this.props.location.query.ehub_access;
  }

  goToLibrary(libraryId) {
    if (parseInt(libraryId) !== -1) {
      const path = this.props.location.pathname.split('/');
      const currentPage = path[path.length - 1];
      let page = 'all';
      if (currentPage === 'selected' || currentPage === 'search') page = currentPage;
      goToURL(`/library/${libraryId}/${page}`);
    }
  }

  handleErrorPopupClose() {
    this.setState({ errorPopupOnError: false });
  }

  render() {
    const {
      location: { pathname },
      config,
      countryConfig,
      library,
      userData,
      userLogout,
      libraries,
      userLibraries,
      loadLibrary,
      children,
      privacyPolicyLink,
    } = this.props;
    const firstPage = pathname === '/' || pathname.match(new RegExp('^/(' + getAvailableLanguages().join('|') + ')/$'));
    const userProfile = pathname.match(new RegExp('^/(' + getAvailableLanguages().join('|') + ')/profile'));
    const userSettings = pathname.match(new RegExp('^/(' + getAvailableLanguages().join('|') + ')/settings'));
    const showBanner = !!(
      firstPage &&
      config &&
      config.get('banner_status') &&
      config.get('banner_status') !== 'inactive'
    );

    const libraryId = getLibraryId();
    let fontFamily = 'inherit';

    if (libraryTheme[libraryId] && libraryTheme[libraryId].font) {
      fontFamily = libraryTheme[libraryId].font;
    }

    return (
      <div className="main-body">
        <CookieConsent />
        <Helmet
          title={
            window.location.pathname.includes('fi')
              ? 'Cineast'
              : window.location.pathname.includes('no')
              ? 'Cineast'
              : 'Cineasterna'
          }
        />

        <div
          className={`content-wrapper  ${firstPage ? ' first-page' : ''} `}
          style={{
            fontFamily: fontFamily,
          }}
        >
          <div id="header-top-row-container" className="container" />

          <LoadingBar isLight={!!firstPage} />

          <CompatibilityWarning />

          {showBanner && <Banner status={config.get('banner_status')} text={config.get('banner_text')} />}

          {this.state.errorPopupOnError && this.props.error && triggerErrorPopup(this.props.errorStatus) && (
            <ErrorPopup errorStatus={this.props.errorStatus} hide={() => this.handleErrorPopupClose()} />
          )}

          {!this.isEhubRedirect() && !firstPage ? (
            <Header
              library={library}
              goToLibrary={this.goToLibrary.bind(this)}
              defaultLibraryId={this.defaultLibraryId}
              userData={userData}
              userLogout={userLogout}
              libraries={libraries}
              userLibraries={userLibraries}
              loadLibrary={loadLibrary.bind(this)}
              hideMidRow={userProfile || userSettings}
              config={config}
              countryConfig={countryConfig}
            />
          ) : null}

          {React.Children.toArray(children)}
        </div>

        <Footer privacyPolicyLink={privacyPolicyLink} config={config} countryConfig={countryConfig} />
        <Modal />
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.node,
};

function mapStateToProps(state) {
  return {
    library: state.get('appReducers').get('library'),
    config: state.get('appReducers').get('config'),
    countryConfig: state.get('appReducers').get('countryConfig'),
    userData: state.get('authReducers').get('userData'),
    privacyPolicyLink: state.get('appReducers').get('privacyPolicyUrl'),
    libraries: state.get('libraryReducers').get('libraries'),
    userLibraries: state.get('libraryReducers').get('userLibraries'),
    error: state.getIn(['errorReducer', 'networkErrors', 'error']),
    errorStatus: state.getIn(['errorReducer', 'networkErrors', 'status']),
  };
}

export default connect(mapStateToProps, {
  loadLibrary,
  fetchUserData,
  setUserData,
  userLogout,
  loadConfiguration,
  loadLibraries,
  loadCountryConfiguration,
  checkNewsletterShown,
  showNewsletterModal
})(App);
