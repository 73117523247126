import * as ActionType from './appActions';
import Immutable from 'immutable';

let defaultState = Immutable.fromJS({
  library: {},
  config: {},
  countryConfig: {},
  privacyPolicyUrl: '',
  newsletterSubscriptionStatus: null,
});

export default function appReducers(state = defaultState, action) {
  switch (action.type) {
    case ActionType.LOADED_LIBRARY:
      return state.merge(action.response);

    case ActionType.LOADED_CONFIGURATION:
      if (action.response && action.response.success) {
        return state.merge({
          config: action.response.config,
          privacyPolicyUrl: String(action.response.privacy_policy_url),
        });
      }
      return state;

    case ActionType.LOADED_COUNTRY_CONFIGURATION:
      if (action.response && action.response.success) {
        return state.merge({
          countryConfig: action.response.config,
        });
      }
      return state;

    case ActionType.NEWSLETTER_SUBSCRIPTION_SUCCEEDED:
      return state.set('newsletterSubscriptionStatus', 'success');

    case ActionType.NEWSLETTER_SUBSCRIPTION_FAILED:
      return state.set('newsletterSubscriptionStatus', 'failed');
      
    default:
      return state;
  }
}
